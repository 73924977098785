import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import DynamicComponent from '../../utils/dynamic-component';
import Layout from '../../components/layout/layout';

function PageDetail({ data, location }) {
  return (
    <Layout
      legalFootNote={data.contentfulPageUtility?.legalFootNote?.legalFootNote}
      location={location}
    >
      {data.contentfulPageUtility.components.filter(component => component?.__typename)
        .map(component => (
          <section key={component.id}>
            {DynamicComponent(component.__typename, component)}
          </section>
        ))}
    </Layout>
  );
}

export const query = graphql`
  query PageUtility($slug: String!) {
    contentfulPageUtility(slug: {eq: $slug}) {
      legalFootNote {
        legalFootNote
      }
      components {
        ...componentContainerUtil
      }
    }
  }

  fragment componentContainerUtil on ContentfulCompContainer {
    id
    __typename
    backgroundImage {
      file {
        url
      }
    }
    backgroundColor
    rows {
      title
      entryTitle
      alignItems
      justifyContent
      alignItems
      spacing
      leftPadding
      topPadding
      rightPadding
      bottomPadding
      columns {
        __typename
        textColor
        columnSize
        isCard
        component {
          __typename
          ...imageUtil
          ...carouselUtil
          ...accordianUtil
          ...richTextWithButtonUtil
          ...stepperUtil
          ...tableUtil
          ...cardUtil
        }
      }
    }
  }

  fragment accordianUtil on ContentfulCompAccordianContainer {
    id
    accordianTitle
    accordianItems {
      accordianBody {
        raw
      }
      accordianHeader
      __typename
    }
  }

  fragment imageUtil on ContentfulCompImageOnly {
    id
    imageTitle
    image {
      file {
        url
        fileName
      }
      gatsbyImageData(
        jpegProgressive: true
        formats: WEBP
        placeholder: BLURRED
      )
    }
  }

  fragment carouselUtil on ContentfulRanCompCarousel {
    slides {
      button {
        ...buttonLinks
      }
      slideLink {
        ...buttonLinks
      }
      date(formatString: "MMMM DD, YYYY")
      entryTitle
      description {
        description
      }
      image {
        gatsbyImageData(
          aspectRatio: 1.78
          jpegProgressive: true
          formats: WEBP
          resizingBehavior: SCALE
          placeholder: BLURRED
        )
      }
      title
      category
    }
    title
  }

  fragment richTextWithButtonUtil on ContentfulCompRichTextWithButton {
    heading
    content {
      raw
    }
    button {
      ...utilityInternalExternalLink
    }
  }

  fragment stepperUtil on ContentfulCompStepperContainer {
    id
    stepperTitle
    stepperNumber
    stepperItems {
      stepperHeader
      stepperBody {
        raw
      }
      stepperButton {
        title
        ...utilityInternalExternalLink
      }
      stepperAssets {
        id
        title
        downloadLink {
          file {
            url
            fileName
          }
        }
        openInANewTab
      }
      stepperImage {
        gatsbyImageData(
          aspectRatio: 1.78
          jpegProgressive: true
          formats: WEBP
          resizingBehavior: SCALE
          placeholder: BLURRED
          cornerRadius: 10
        )
      }
    }
  }

  fragment tableUtil on ContentfulCompTable {
    id
    tableHeader
    entryTitle
    tableRows {
      id
      entryTitle
      cellValues
    }
  }

  fragment cardUtil on ContentfulCompRichTextBlock {
    id
    __typename
    entryTitle
    richText {
      raw
    }
  }

  fragment utilityInternalExternalLink on ContentfulRanCompLink {
    link {
      ... on ContentfulInternalLink {
        openInANewTab
        entryTitle
        refPage {
          __typename
          ... on ContentfulPageProgram {
            slug
          }
          ... on ContentfulPageUtility {
            slug
          }
          ... on ContentfulPageEvent {
            slug
          }
          ... on ContentfulPageKnowledge {
            slug
          }
        }
      }
      ... on ContentfulCompExternalLink {
        title
        url {
          url
        }
        openInANewTab
        logo {
          file {
            fileName
            url
            contentType
          }
        }
      }
    }
  }
`;
PageDetail.propTypes = {
  location: PropTypes.shape.isRequired,
  data: PropTypes.shape.isRequired
};

export default PageDetail;
